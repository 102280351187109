import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from "./redux/store";
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import Auth0ProviderWithNavigate from './auth0-provider-with-navigate';
import { setupSimpleApolloClient } from "./utils/apolloHelper";


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<Provider store={ store }>
		{/*<ApolloProvider client={ setupSimpleApolloClient() }>*/}
			<BrowserRouter>
				<Auth0ProviderWithNavigate>
					<App/>
				</Auth0ProviderWithNavigate>
			</BrowserRouter>
		{/*</ApolloProvider>*/}
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
