import { MdMenuOpen } from "react-icons/md";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { selectUserInfoState } from "../../../redux/slices/userInfoSlice";
import { useAppSelector } from "../../../redux/hooks";


export interface LayoutHeaderProps {
	onMenuClick: () => void;
}

export const LayoutHeader = ({ onMenuClick }: LayoutHeaderProps) => {
	const navigate = useNavigate();
	const userInfo = useAppSelector(selectUserInfoState)
	const { logout } = useAuth0();


	function onLogout() {
		logout({ openUrl: false })
	}


	return (
		<div className="flex flex-row h-14 py-2 pr-2 w-full">
			<div className="bg-black w-full rounded-lg flex flex-row justify-between items-center">
				<div className="ml-2">
					{/*<MdMenuOpen className="cursor-pointer h-full rounded-2xl" size={ "1.8rem" } onClick={ onMenuClick }/>*/ }
				</div>
				<div className="mr-2 flex justify-center items-center">
					{/*<MdMenuOpen className="cursor-pointer h-full" size={ "1.8rem" }/>*/ }


					<div className="dropdown dropdown-end">
						{/*<div className="flex justify-center items-center">*/ }
						<div tabIndex={ 0 } role="button" className="btn btn-circle btn-sm avatar">
							<div className="rounded-full">
								<img alt="Tailwind CSS Navbar component" src={ userInfo.user?.picture ?? '' }/>
							</div>
							{/*</div>*/ }
						</div>
						<ul tabIndex={ 0 } className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
							<li>
								<a className="justify-between">
									Profile
									{/*<span className="badge">New</span>*/ }
								</a>
							</li>
							{/*<li><a>Settings</a></li>*/ }
							<li><a onClick={ onLogout }>Logout</a></li>
						</ul>
					</div>


				</div>
			</div>
		</div>
	)
}



