import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import uiSettingsReducer from './slices/uiSettingsSlice';
import userInfoReducer from './slices/userInfoSlice';

export const store = configureStore({
	reducer: {
		uiSettings: uiSettingsReducer,
		userInfo: userInfoReducer,
	},
	devTools: process.env.NODE_ENV === 'development'
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
	RootState,
	unknown,
	Action<string>>;
