import { EvaluationQuizFormType } from "../pages/Evaluation/AddEditQuiz";
import joi from 'joi';
import { msg } from './messages/evaluation';


export const evalQuizValidationSchema: joi.Schema<EvaluationQuizFormType> = joi.object({
	_id: joi.string().optional(),
	monthMin: joi.number().required().messages(msg.month),
	monthMax: joi.number().min(joi.ref('monthMin')).required().messages(msg.month),
	questions: joi.array().min(1).unique('question').unique('order').items(joi.object({
			order: joi.number().integer().required(),
			question: joi.string().required(),
			answers: joi.array().min(1).unique('order').unique('text').message(msg.answersText["array.unique"]).unique('value').message(msg.answersValue["array.unique"]).items(joi.object({
				order: joi.number().integer().required(),
				text: joi.string().required(),
				value: joi.number().integer().required()
			})).required().messages(msg.answers).options({ allowUnknown: true })
		})).required().messages(msg.questions).options({ allowUnknown: true }),
}).options({ allowUnknown: true })

