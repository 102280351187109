import { useLocation, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { EvaluationQuizInput } from "shared-types";
import { useGetQuizByIdLazyQuery, useSaveQuizMutation } from "../../_generated/graphql";
import { joiResolver } from "@hookform/resolvers/joi";
import { isArray } from "@apollo/client/utilities";
import { AnswersFieldArray } from "./AnswersFieldArray";
import { useEffect } from "react";
import { evalQuizValidationSchema } from "../../validation/evaluationQuizValidation";
import { FormTextInput } from "../../components/Form/FormTextInput";
import { FormSelect } from "../../components/Form/FormSelect";
import { MdDelete, MdOutlineArrowDownward, MdOutlineArrowUpward } from "react-icons/md";


export type EvaluationQuizFormType = Omit<EvaluationQuizInput, 'questions'> & { questions: { value: string }[] }

export const AddEditQuiz = () => {
	const navigate = useNavigate();
	const { state: locState } = useLocation();

	// const { data: questionsData, loading: questionDataLoading, error: questionDataError } = useGetQuestionsQuery();
	const [ saveQuiz, { loading: saveQuizLoading, error: saveQuizError } ] = useSaveQuizMutation();
	const [ getQuizById, { loading: quizByIdLoading, error: quizByIdError } ] = useGetQuizByIdLazyQuery();

	const isEditing = Boolean(locState?._id);


	useEffect(() => {
		// console.log(locState?._id)
		if (locState?._id) {
			getQuizById({ variables: { id: locState._id } })
				.then(value => {
					if (!value.data?.getQuizById)
						navigate('/evaluation/quiz')
					else {
						// setFormDefaultValues(value.data?.getQuizById)
						reset(value.data.getQuizById);
					}
				})
		}
	}, []);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		getValues,
		control,
		reset,
		trigger
	} = useForm<EvaluationQuizInput>({
		resolver: joiResolver(evalQuizValidationSchema),
	});

	const { fields: questionFields, append, remove, swap, move } = useFieldArray({
		name: 'questions',
		control
	})


	const onSubmit: SubmitHandler<EvaluationQuizInput> = (data) => {
		console.log(data)
		saveQuiz({
			variables: {
				quiz: {
					_id: data._id,
					monthMax: data.monthMax,
					monthMin: data.monthMin,
					questions: data.questions.map(q => ({
						_id: q._id,
						order: q.order,
						question: q.question,
						answers: q.answers.map(a => ({ _id: a._id, order: a.order, text: a.text, value: a.value }))
					}))
				}
			}
		})
			.then((result) => {
				if (result)
					navigate('../');
			})
			.catch(reason => {
				console.log(reason)
			})
	}


	return (
		<div className="flex flex-1 flex-col">
			<form onSubmit={ handleSubmit(onSubmit) }>
				<div className="flex flex-1 flex-col">
					{ isEditing ?
						<>
							<FormTextInput defaultValue={ getValues('monthMin') } disabled/>
							<FormTextInput defaultValue={ getValues('monthMax') } disabled/>
						</>
						:
						<div className="flex flex-row justify-around">
							<fieldset>
								<legend>From</legend>
								<Controller name={ "monthMin" } control={ control } render={ ({ field: { value, onChange, onBlur }, fieldState, formState }) => (
									<div className="flex flex-row items-center gap-x-3">
										<FormSelect options={ Array.from({ length: 18 }, (_, i) => i.toString()) }
													onChange={ value1 => {
														setValue('monthMin', isNaN(value) ? 12 * +value1 : 12 * +value1 + (getValues('monthMin') % 12));
														trigger([ 'monthMin', 'monthMax' ]);
													} }
													value={ !isNaN(value) ? Math.floor(value / 12).toString() : '0' }/>
										<span>Years</span>
										<FormSelect options={ Array.from({ length: 12 }, (_, i) => i.toString()) }
													onChange={ value1 => {
														setValue('monthMin', isNaN(value) ? +value1 : +value1 + Math.floor(getValues('monthMin') / 12) * 12);
														trigger([ 'monthMin', 'monthMax' ]);
													} }
													value={ !isNaN(value) ? Math.floor(value / 12).toString() : '0' }/>
										<span>Months</span>
									</div>
								) }/>
							</fieldset>

							<fieldset>
								<legend>To</legend>
								<Controller name={ "monthMax" } control={ control } render={ ({ field: { value, onChange, onBlur }, fieldState, formState }) => (
									<div className="flex flex-row items-center gap-x-3">
										<FormSelect options={ Array.from({ length: 18 }, (_, i) => i.toString()) }
													onChange={ value1 => {
														setValue('monthMax', isNaN(value) ? 12 * +value1 : 12 * +value1 + (getValues('monthMax') % 12));
														trigger([ 'monthMin', 'monthMax' ]);
													} }
													value={ !isNaN(value) ? Math.floor(value / 12).toString() : '0' }/>
										<span>Years</span>
										<FormSelect options={ Array.from({ length: 12 }, (_, i) => i.toString()) }
													onChange={ value1 => {
														setValue('monthMax', isNaN(value) ? +value1 : +value1 + Math.floor(getValues('monthMax') / 12) * 12);
														trigger([ 'monthMin', 'monthMax' ]);
													} }
													value={ !isNaN(value) ? Math.floor(value / 12).toString() : '0' }/>
										<span>Months</span>
									</div>
								) }/>
							</fieldset>

						</div>
					}

					<span className="">{ errors.monthMin?.message }</span>
					<span className="">{ errors.monthMax?.message }</span>

					<div className="">
						{
							questionFields.map((f, index) => (
								<div key={ f.id } className="border my-6 rounded-lg p-4">
									<div className="flex flex-row justify-between items-center gap-x-36">
										<div className="flex flex-col flex-1">
											<FormTextInput label="Question" { ...register(`questions.${ index }.question`) } containerClassName="flex-1" />
											{/*<Controller name={ `questions.${ index }.question` } control={ control }*/}
											{/*			render={ ({field: { value, onChange }, fieldState, formState}) => (*/}
											{/*	<textarea className="resize p-1.5 h-max w-full" { ...register(`questions.${ index }.question`) } rows={ 1 } cols={ 55 } />*/}
											{/*	)} />*/}
											<span className="">{ errors.questions?.[index]?.question?.message }</span>
											<input type={ "hidden" } { ...register(`questions.${ index }.order`) } defaultValue={ index }/>
										</div>
										<div className="flex flex-row justify-center items-center gap-x-3 p-2">
											<div className="flex flex-row justify-center items-center gap-x-3">
												{ index > 0 &&
													<button type="button" className="btn btn-circle btn-outline" onClick={ () => {
														move(index, index - 1);
														setValue(`questions.${ index }.order`, index);
														setValue(`questions.${ index - 1 }.order`, index - 1);
													} }>
														<MdOutlineArrowUpward/>
													</button>
												}
												{ index < questionFields.length - 1 &&
													<button type="button" className="btn btn-circle btn-outline" onClick={ () => {
														move(index, index + 1);
														setValue(`questions.${ index }.order`, index);
														setValue(`questions.${ index + 1 }.order`, index + 1);
													} }>
														<MdOutlineArrowDownward/>
													</button>
												}
											</div>
											<button type="button" className="btn btn-circle bg-red-600" onClick={ () => remove(index) } color={ "error" }>
												<MdDelete/>
											</button>
										</div>
									</div>
									<div className="flex flex-row h-px w-full bg-gray-500 my-2"></div>
									<AnswersFieldArray questionIndex={ index } control={ control } setValue={ setValue } register={ register } errors={ errors }/>
								</div>
							)) }
					</div>
					<span className="">{ errors.questions?.message }</span>
					{ errors.questions && isArray(errors.questions) && errors.questions.map!((e, index) => (
						e && e.root &&
						<span key={ index } className="">{ e.root.message }</span>
					)) }
					<div className="flex flex-row justify-end">
						<button type="button" className="btn" onClick={ () => {
							append({ question: '', order: questionFields.length, answers: [] })
						} }>Add Question
						</button>
					</div>

				</div>
				<div className="flex flex-row justify-between">
					<button type="button" className="btn btn-error" onClick={ () => navigate('/evaluation/quiz') }>Cancel</button>
					<button className="btn btn-secondary" type={ 'submit' }>Save</button>
				</div>
			</form>
		</div>
	);
};


