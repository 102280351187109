import { EvaluationQuiz } from "shared-types";
import { useMemo } from "react";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";


interface QuizzesTableProps {
	data: EvaluationQuiz[];
	onEditClick: (quizId: string) => void;
	onDeleteClick: (quizId: string, monthMin: number, monthMax: number) => void;
}

export const QuizzesTable = ({ data, onEditClick, onDeleteClick }: QuizzesTableProps) => {

	const rowData = useMemo(() => data, [ data ]);


	const columns = useMemo<ColumnDef<EvaluationQuiz>[]>(
		() => [
			{
				header: 'Month',
				cell: ({ row }) => (
					<FormatMonthHeadText monthMin={ row.original.monthMin } monthMax={ row.original.monthMax } />
					// <div>{ formatMonthHeadText(row.original.monthMin, row.original.monthMax) }</div>
				),
			},
			{
				header: 'Questions',
				cell: props => {
					return <>
					{props.row.original.questions.map(q => (
						<div key={ `quiz_${q._id}` } className="flex flex-row justify-around">
							<div className="flex-[0.5] justify-start">{ q.question }</div>
							<div className="flex-[0.5] justify-start">{ q.answers.map(a => `${ a.text } - ${ a.value }`).join(' | ')}</div>
						</div>
					))}
						</>
				}
			},
			{
				header: 'Action',
				cell: props => {
					return (
						<div className="flex flex-row gap-x-3">
							<button type="button" className="btn"
									onClick={ () => onEditClick(props.row.original._id!) }>Edit</button>
							<button type="button" className="btn btn-error"
									onClick={ () => onDeleteClick(props.row.original._id!, props.row.original.monthMin, props.row.original.monthMax) }
									>Delete</button>
						</div>
					)
				}
			}
		],
		[ onEditClick, onDeleteClick ])

	const table = useReactTable({
		data: rowData,
		columns,
		// pageCount: dataQuery.data?.pageCount ?? -1,
		// state: {
		// 	pagination,
		// },
		// onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
		manualPagination: true,
		// getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
		debugTable: true,
	});


	return (

		<div>
			<table className="table">
				<thead>
					{ table.getHeaderGroups().map(headerGroup => (
						<tr key={ headerGroup.id }>
							{ headerGroup.headers.map(header => {
								return (
									<th key={ header.id } colSpan={ header.colSpan }>
										{ header.isPlaceholder ? null : (
											flexRender(
												<span className="">
													{/*{ header.column.columnDef.header }*/ }
													{ header.column.columnDef.header!.toString() }
												</span>,
												header.getContext()
											)
										) }
									</th>
								)
							}) }
						</tr>
					)) }
				</thead>
				<tbody>
					{ table.getRowModel().rows.map(row => {
						return (
							<tr key={ row.id } className="hover">
								{ row.getAllCells().map(cell => {
									return (
										<td key={ cell.id }>
											{ flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											) }
										</td>
									)
								}) }
							</tr>
						)
					}) }
				</tbody>
			</table>

		</div>

	);
};




export const FormatMonthHeadText = ({ monthMin, monthMax }: { monthMin: number, monthMax: number }) => {
	const from = monthMin === 0 ? '0' : `${ monthMin > 12 ? Math.floor(monthMin / 12) + ' Years ' : '' }${ monthMin % 12 > 0 ? (monthMin % 12) + ' months' : ''}`;
	const to = `${ monthMax > 12 ? Math.floor(monthMax / 12) + ' Years ' : '' }${ monthMax % 12 > 0 ? (monthMax % 12) + ' months ' : '' }`
	return (
		<div>From <pre>{ from }</pre> to <pre>{ to }</pre></div>
	)
}


