import React, { useEffect, useMemo } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Authenticated } from "./pages/Auth/Authenticated";
import { Unauthenticated } from "./pages/Auth/Unauthenticated";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { selectUserInfoState, setToken } from "./redux/slices/userInfoSlice";


function App() {

	const dispatch = useAppDispatch();
	const userInfo = useAppSelector(selectUserInfoState)

	const { isAuthenticated, isLoading: authLoading, user: auth0User, error: auth0Error, getAccessTokenSilently, logout } = useAuth0();

	useEffect(() => {
		const auth = async () => {
			if (auth0User) {
				try {
					const accToken = await getAccessTokenSilently();
					// setApolloToken(accToken);
					// console.log(accToken)
					// const { data } = await execMeQuery();
					// console.log(data?.me)
					dispatch(setToken(accToken));
				} catch (e: any) {
					// TODO
					console.log(e)
					if (e.error === "missing_refresh_token") {
						logout({ openUrl: false });
					}
				}
			}
		};

		auth();

	}, [ auth0User ]);


	const authLink = useMemo(() => setContext((_, { headers }) => {
		return {
			headers: {
				...headers,
				authorization: userInfo.token,
			}
		}
	}), [ userInfo.token ]);
	const httpLink = useMemo(() =>
		new HttpLink({
			uri: process.env.REACT_APP_API_URL,
		}), []);
	const apolloClient = useMemo(() =>
			new ApolloClient({
				cache: new InMemoryCache(),
				link: authLink.concat(httpLink)
			})
		, [ authLink, httpLink ])


	if (auth0Error) {
		return (
			<div>
				<h3>Auth0 Error:</h3>
				<p>{ auth0Error.name }</p>
				<p>{ auth0Error.message }</p>
				<p>{ auth0Error.stack }</p>
			</div>
		)
	}

	if (authLoading) {
		return (
			<div>Logging in....</div>
		)
	}

	if (isAuthenticated && !userInfo.token) {
		return (
			<div>Loading token....</div>
		)
	}


	return (
		(isAuthenticated && auth0User) ?
			<ApolloProvider client={ apolloClient }>
				<Authenticated/>
			</ApolloProvider>
			:
			<Unauthenticated/>
	)

}

export default App;
