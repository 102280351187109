import { IconType } from "react-icons";
import { MdArrowRight, MdOutlineQuestionMark } from "react-icons/md";
import { ChildRoutesType } from "../../../routes";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";


export interface SidebarItemProps {
	ItemIcon?: IconType;
	text: string;
	childItems?: ChildRoutesType[];
	route?: string;
}

export const SidebarItem = ({ ItemIcon, text, route, childItems }: SidebarItemProps) => {

	const { pathname } = useLocation();
	let subroute = '';


	const [ open, setOpen ] = useState(pathname.startsWith(`/${ route }`));
	if (open && route) {
		subroute = pathname.substring(route.length + 2);
	}

	const onItemClick = () => {
		childItems && setOpen(!open);
	};

	return (
		<div className={ classNames([ "flex flex-col flex-1 items-stretch justify-stretch mx-3 my-2 pb-1 cursor-pointer pt-1 rounded-md", /*{ "bg-gray-700": open }*/ ]) }>
			<div className={ classNames([ "flex flex-row items-center justify-between ml-1 p-1 rounded-md", { "bg-gray-700": open } ]) } onClick={ onItemClick }>
				<div className={ classNames([ "flex flex-row items-center justify-center ml-0.5" ]) }>
					{
						ItemIcon ?
							<ItemIcon size={ "1.5rem" }/>
							:
							<MdOutlineQuestionMark size="1.6rem"/>
					}
					{ childItems ?
						<span className={ classNames([ "text-lg ml-2 overflow-ellipsis duration-500" ]) }>{ text }</span>
					:
						<Link to={ route! } className={ classNames([ "text-lg ml-2 overflow-ellipsis duration-500" ]) }>{ text }</Link>
					}
				</div>
				{ childItems &&
					<MdArrowRight size="1.4rem" className={ classNames([ "justify-self-end transition-all", { "rotate-90": open }, "visible" ]) }/>
				}
			</div>
			{ childItems &&
				<div className={ classNames([ "pl-8 overflow-hidden transition-all ease-in-out duration-300", !open ? "h-0" : `h-[${ 2.5 * childItems.length }rem]` ]) }>
					{ childItems.map((item, index) => {
						if (!item.title) return;
						return (
						<Link to={ `${ route }/${ item.path ?? "" }` } key={ `${ item.key }-${ index }` }>
							<div className={ classNames([ "my-[0.5rem] h-[2rem] pl-2 rounded-md flex flex-row justify-start items-center", `${ subroute !== item.path ? "hover:" : "" }bg-gray-800` ]) }>
								{ item.title }
							</div>
						</Link>
					)})
					}
				</div>
			}
		</div>
	)
}

