import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { UnauthLayout } from "../../components/layout/UnauthLayout";
import { SignIn } from "./SignIn";


interface UnauthenticatedProps {
}

export const Unauthenticated = (props: UnauthenticatedProps) => {

	return (
		<Routes>
			<Route element={ <UnauthLayout/> }>
				<Route element={ <SignIn/> } path="signIn"/>
			</Route>
			<Route path="*" element={ <Navigate to="/signIn" replace={ true }/> }/>
		</Routes>
	);

}

