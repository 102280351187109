import { useDeleteQuizMutation, useGetQuizzesQuery } from "../../_generated/graphql";
import { QuizzesTable } from "./quizzesTable";
import { useNavigate } from "react-router-dom";
import { useRef, useState } from "react";


export const Quiz = () => {
	const navigate = useNavigate();

	const [ deleteDialogOpen, setDeleteDialogOpen ] = useState(false);
	const deleteQuizInfoRef = useRef({ id: '', month: -1 });

	const { data: quizData, error: quizDataError, loading: quizDataLoading, refetch: refetchQuizzes } = useGetQuizzesQuery({ fetchPolicy: "network-only" });
	const [ deleteQuiz, { loading: deleteLoading } ] = useDeleteQuizMutation();

	const onQuizEditClick = (quizId: string) => {
		console.log('edit ', quizId)
		navigate(`${quizId}`, { state: { _id: quizId } });
	}

	const onQuizDeleteClick = (quizId: string, month: number) => {
		deleteQuizInfoRef.current.id = quizId;
		deleteQuizInfoRef.current.month = month;
		setDeleteDialogOpen(true);
	}

	const onDeleteDialogCancelClick = () => {
		setDeleteDialogOpen(false);
	}

	const onDeleteDialogDeleteClick = () => {
		deleteQuiz({ variables: { quizId: deleteQuizInfoRef.current.id }})
			.then(result => {
				refetchQuizzes();
			})
			.catch(reason => {
				// console.log(reason)
			})
			.finally(() => {
				setDeleteDialogOpen(false);
			})
	}

	const onAddQuiz = () => {
		navigate('add')
	}


	if (quizDataLoading)
		return (
			<div>Loading...</div>
		)

	if (quizDataError)
		return (
			<div>{ quizDataError.message }</div>
		)


	return (

		<div className="flex flex-1 flex-col">
			{/*<QuestionsTable data={ quizData?.getQuizzes! } onEditClick={ onQuizEditClick } onDeleteClick={ onQuizDeleteClick } />*/}
			<QuizzesTable data={ quizData?.getQuizzes! } onEditClick={ onQuizEditClick } onDeleteClick={ onQuizDeleteClick } />
			<div className="flex flex-row justify-end m-3">
				<button className="btn" onClick={ onAddQuiz }>Add</button>
			</div>
		</div>



	);
};



