"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Weight_Unit = exports.Symptom = exports.Role = exports.Height_Unit = exports.Gender = void 0;
var Gender;
(function (Gender) {
    Gender["FEMALE"] = "FEMALE";
    Gender["MALE"] = "MALE";
})(Gender || (exports.Gender = Gender = {}));
var Height_Unit;
(function (Height_Unit) {
    Height_Unit["CM"] = "CM";
    Height_Unit["INCH"] = "INCH";
})(Height_Unit || (exports.Height_Unit = Height_Unit = {}));
var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["USER"] = "USER";
})(Role || (exports.Role = Role = {}));
var Symptom;
(function (Symptom) {
    Symptom["ABNORMAL_BREATHING"] = "ABNORMAL_BREATHING";
    Symptom["COUGH"] = "COUGH";
    Symptom["GENERAL_FUSSINESS"] = "GENERAL_FUSSINESS";
    Symptom["HIGH_FEVER"] = "HIGH_FEVER";
    Symptom["LOW_ENERGY"] = "LOW_ENERGY";
    Symptom["NO_APPETITE"] = "NO_APPETITE";
    Symptom["RASH"] = "RASH";
    Symptom["RUNNY_NOSE"] = "RUNNY_NOSE";
    Symptom["SPIT_UP"] = "SPIT_UP";
    Symptom["VOMITING"] = "VOMITING";
})(Symptom || (exports.Symptom = Symptom = {}));
var Weight_Unit;
(function (Weight_Unit) {
    Weight_Unit["KG"] = "KG";
    Weight_Unit["LB"] = "LB";
})(Weight_Unit || (exports.Weight_Unit = Weight_Unit = {}));
