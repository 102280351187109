import React, { ReactNode, useEffect, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "../../components/layout/Layout";
import { ChildRoutesType, RoutesType, staticRoutes } from "../../routes";
import { useMeLazyQuery } from "../../_generated/graphql";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setApolloToken } from "../../utils/apolloHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { selectUserInfoState, setToken, setUserInfo } from "../../redux/slices/userInfoSlice";


interface AuthenticatedProps {
}

export const Authenticated = (props: AuthenticatedProps) => {
	const dispatch = useAppDispatch();
	const userInfo = useAppSelector(selectUserInfoState)

	const routes = useMemo(() => getRoutes(staticRoutes), []);

	const { user: auth0User, getAccessTokenSilently } = useAuth0();
	const [ execMeQuery, { data: meQueryData, loading: meQueryLoading, error: meQueryError, called: meQueryCalled } ] = useMeLazyQuery({ fetchPolicy: "network-only" });

	useEffect(() => {
		const getUserInfo = async () => {
			if (userInfo.token) {
				const { data } = await execMeQuery();
				dispatch(setUserInfo(data?.me!));
			}
		}

		getUserInfo();
	}, [ userInfo.token ]);

	if (meQueryError) {
		return (
			<div>
				<h3>Loading user Error:</h3>
				<p>{ meQueryError.name }</p>
				<p>{ meQueryError.message }</p>
				<p>{ meQueryError.stack }</p>
			</div>
		)
	}


	if (meQueryLoading || !userInfo || !userInfo.token) {
		return (
			<div>Loading user....</div>
		)
	}


	return (
		<Routes>
			<Route element={ <Layout/> }>
				{/*{ staticRoutes.map(r => (*/ }
				{/*		<Route key={ r.key } path={ r.path } element={ r.element }/>*/ }
				{/*	)*/ }
				{/*) }*/ }
				{ routes }
			</Route>
			<Route path="*" element={ <Navigate to="/dashboard" replace={ true }/> }/>
		</Routes>
	);

}


const getRoutes = (routes?: RoutesType[] | ChildRoutesType[]): ReactNode => {
	if (!routes) return null;

	return routes.map(r => (
		r.type !== 'divider' ?
			<Route key={ r.key } path={ r.path } element={ r.element }>
				{ r.children ? getRoutes(r.children) : null }
			</Route>
			: null
	));

}
