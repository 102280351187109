import { Control, Controller, FieldErrors, useFieldArray } from "react-hook-form";
import { UseFormRegister, UseFormSetValue } from "react-hook-form/dist/types/form";
import { EvaluationQuizInput } from "shared-types";
import { FormTextInput } from "../../components/Form/FormTextInput";
import { MdArrowDownward, MdArrowUpward, MdDelete } from "react-icons/md";


export const AnswersFieldArray = ({ questionIndex, control, setValue, register, errors }: {
	questionIndex: number,
	control: Control<EvaluationQuizInput, any>;
	setValue: UseFormSetValue<EvaluationQuizInput>;
	register: UseFormRegister<EvaluationQuizInput>;
	errors: FieldErrors<EvaluationQuizInput>;
}) => {


	const { fields, append, remove, swap, move } = useFieldArray({
		name: `questions.${ questionIndex }.answers`,
		control
	})


	return (
		<div className="flex flex-col justify-center">
			{ fields.map((f, index) => (
				<div className="flex flex-col" key={ f.id }>
					<div className="flex flex-row justify-between">
						<div className="flex flex-row px-2 gap-x-3">
							<FormTextInput label={ `Answer ${ index + 1 }` } { ...register(`questions.${ questionIndex }.answers.${ index }.text`) } />
							<span className="">{ errors.questions?.[questionIndex]?.answers?.[index]?.text?.message }</span>
							<Controller name={ `questions.${ questionIndex }.answers.${ index }.value` } control={ control }
										render={ ({ field: { onChange, onBlur, value }, fieldState, formState }) => (
											<FormTextInput label={ `Answer Value ${ index + 1 }` } type={ 'number' }
														   defaultValue={ value }
														   onChange={ event => {
															   setValue(`questions.${ questionIndex }.answers.${ index }.value`, +event.target.value)
														   } }
											/>
										) }
							/>
							<input type={ "hidden" } { ...register(`questions.${ questionIndex }.answers.${ index }.order`) } defaultValue={ index }/>
						</div>
						<div className="flex flex-row justify-end gap-x-3">
							<div className="flex flex-row justify-end gap-x-3">
								{ index > 0 &&
									<button type="button" className="btn btn-circle btn-outline" onClick={ () => {
										move(index, index - 1);
										setValue(`questions.${ questionIndex }.answers.${ index }.order`, index);
										setValue(`questions.${ questionIndex }.answers.${ index - 1 }.order`, index - 1);
									} }>
										<MdArrowUpward/>
									</button>
								}
								{ index < fields.length - 1 &&
									<button type="button" className="btn btn-circle btn-outline" onClick={ () => {
										move(index, index + 1)
										setValue(`questions.${ questionIndex }.answers.${ index }.order`, index);
										setValue(`questions.${ questionIndex }.answers.${ index + 1 }.order`, index + 1);
									} }>
										<MdArrowDownward/>
									</button>
								}
							</div>
							<button type="button" className="btn btn-circle bg-red-600" onClick={ () => remove(index) } color={ "error" }>
								<MdDelete/>
							</button>
						</div>
					</div>
					<span className="">{ errors.questions?.[questionIndex]?.answers?.[index]?.root?.message }</span>
				</div>
			)) }
			<div className="flex flex-row justify-end">
				<button type="button" className="btn" onClick={ () => {
					append({ text: '', order: fields.length, value: 0 })
				} }>Add Answer
				</button>
			</div>
		</div>



	)
}



