

export const msg = {
	"month": {
		"number.base": "Please select month.",
		"string.empty": "Please select month.",
		"any.required": "Please select month."
	},
	"questions": {
		"array.min": "Please add at least 1 question",
		"string.empty": "Question cannot be empty.",
		"any.required": "Question cannot be empty.",
		"array.unique": "Please select different questions."
	},
	"answers": {
		"string.empty": "Answer cannot be empty.",
		"any.required": "Answer cannot be empty.",
		"array.unique": "Answer must be unique.",

		// "array.unique": "Please select different questions."
		// "string.empty": "Please select questions.",
		// "any.required": "Please select questions.",

	},
	"answersText": {
		"array.unique": "Answer must be unique"
	},
	"answersValue": {
		"array.unique": "Value must be unique"
	},
}


