import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";



export type SideNavColors = "primary"
	| "secondary"
	| "info"
	| "success"
	| "warning"
	| "error"
	| "light"
	| "dark";

type LayoutTypes = "dashboard" | "page";

interface UiSettingsState {
	miniSidenav: boolean;
	transparentSidenav: boolean;
	whiteSidenav: boolean;
	sidenavColor: SideNavColors;
	transparentNavbar: boolean;
	fixedNavbar: boolean;
	openConfigurator: boolean;
	layout: LayoutTypes;
	darkMode: boolean;
	loading: boolean;
}

const initialState: UiSettingsState = {
	miniSidenav: false,
	transparentSidenav: false,
	whiteSidenav: false,
	sidenavColor: "info",
	transparentNavbar: true,
	fixedNavbar: true,
	openConfigurator: false,
	layout: "dashboard",
	darkMode: false,
	loading: false
}


const localStorageState = () => {
	try {
		return JSON.parse(localStorage.getItem('uiSettings') || '');
	} catch (e) {
		return undefined;
	}
}


export const uiSettingsSlice = createSlice({
	name: 'uiSettings',
	initialState: localStorageState() ?? initialState,
	reducers: {
		setMiniSideNav: (state, action: PayloadAction<boolean>) => {
			state.miniSidenav = action.payload;
		},
		setTransparentSidenav: (state, action: PayloadAction<boolean>) => {
			state.transparentSidenav = action.payload;
		},
		setWhiteSideNav: (state, action: PayloadAction<boolean>) => {
			state.whiteSideNav = action.payload;
		},
		setSidenavColor: (state, action: PayloadAction<SideNavColors>) => {
			state.sidenavColor = action.payload;
		},
		setTransparentNavbar: (state, action: PayloadAction<boolean>) => {
			state.transparentNavbar = action.payload;
		},
		setFixedNavbar: (state, action: PayloadAction<boolean>) => {
			state.fixedNavbar = action.payload;
		},
		setOpenConfigurator: (state, action: PayloadAction<boolean>) => {
			state.openConfigurator = action.payload;
		},
		setLayout: (state, action: PayloadAction<LayoutTypes>) => {
			state.layout = action.payload;
		},
		setDarkMode: (state, action: PayloadAction<boolean>) => {
			state.darkMode = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
	}
});


export const {
	setMiniSideNav, setTransparentSidenav, setWhiteSideNav, setSidenavColor,
	setTransparentNavbar, setFixedNavbar, setOpenConfigurator, setLayout,
	setDarkMode, setLoading
} = uiSettingsSlice.actions;

export const selectUiState = (state: RootState) => state.uiSettings;

export default uiSettingsSlice.reducer;

