import { IconType } from "react-icons";
import { MdOutlineDashboard, MdPerson, MdQuiz } from "react-icons/md";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { Users } from "./pages/Users/Users";
import { EditUser } from "./pages/Users/editUser";
import { Quiz } from "./pages/Evaluation/Quiz";
import { AddEditQuiz } from "./pages/Evaluation/AddEditQuiz";
import { ReactNode } from "react";


export type ChildRoutesType = { type?: "link" | "collapse" | "divider" | "hidden", title?: string, key?: string, path: string, icon?: IconType, element?: ReactNode, children?: ChildRoutesType[] };
export type RoutesType = { type: "link" | "collapse" | "divider" | "hidden", title?: string, key?: string, path: string, icon?: IconType, element?: ReactNode, children?: ChildRoutesType[] };


export const staticRoutes: RoutesType[] = [
	{
		type: "link",
		title: "Dashboard",
		path: "dashboard",
		key: "dashboard",
		element: <Dashboard/>,
		icon: MdOutlineDashboard,
	},
	{
		type: "link",
		title: "Users",
		path: "users",
		key: "users",
		// element: <Users />,
		icon: MdPerson,
		children: [
			{
				type: "hidden",
				key: "users_index",
				path: "",
				element: <Users/>
			},
			{
				type: "hidden",
				key: "users_edit",
				path: ":userId",
				element: <EditUser/>
			}
		]
	},
	{
		type: "link",
		title: "Quiz",
		path: "quiz",
		key: "quiz",
		icon: MdQuiz,
		// element: <Evaluation />,
		children: [
			{
				type: "hidden",
				key: "quiz_edit",
				path: "",
				element: <Quiz/>,
			},
			{
				type: "hidden",
				key: "quiz_add",
				path: "add",
				element: <AddEditQuiz/>,
			},
			{
				type: "hidden",
				key: "quiz_edit",
				path: ":quizId",
				element: <AddEditQuiz/>,
			},
		]
	}
]

