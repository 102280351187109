import React from "react";
import { useAuth0 } from "@auth0/auth0-react";


interface SignInProps {
}

export const SignIn = (props: SignInProps) => {

	const { loginWithRedirect, isAuthenticated, isLoading: authLoading, user: auth0User, error: auth0Error, getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();


	async function onSignIn() {
		await loginWithRedirect({
			appState: {
				returnTo: window.location.pathname
			},
		});
	}


	return (
		<div className="flex flex-1 justify-center items-center h-screen">
			<button className="btn" onClick={ onSignIn }>Sign in</button>
		</div>
	);

}

