import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/hooks";
import { RoutesType, staticRoutes } from "../routes";


export const useAppRoutes = () => {

	const [ routes, setRoutes ] = useState<RoutesType[]>(staticRoutes)

	return routes;
}

