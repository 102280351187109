import React, { InputHTMLAttributes, PropsWithChildren, Ref } from "react";
import classNames from "classnames";


interface FormTextInputProps extends PropsWithChildren<InputHTMLAttributes<HTMLInputElement>> {
	label?: string;
	className?: string;
	placeholder?: string;
	error?: string;
	containerClassName?: string;
	inputClassName?: string;
	// onBlur?: (event: FocusEvent<HTMLInputElement>) => void
}

export const FormTextInput = React.forwardRef<HTMLInputElement, FormTextInputProps>(({ className, label, children, placeholder, error, inputClassName, containerClassName, ...rest }: FormTextInputProps, ref) => {
	return (
		<label className={ classNames([ "form-control", containerClassName ]) }>
			<div className="label">
				<span className="label-text">{ label }</span>
			</div>
			<input type="text" placeholder={ placeholder } className={ classNames([ "input input-bordered w-full", inputClassName ]) } ref={ ref }
				   onKeyDown={ event => {
					   event.key === 'Enter' && event.preventDefault();
				   } }
				   { ...rest } />
			<div className="label">
				<span className={ `label-text-alt text-error ${ !error && 'hidden' }` }>{ error }</span>
			</div>
		</label>
	)
})

