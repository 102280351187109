import classNames from "classnames";
import { SidebarHeader } from "./SidebarHeader";
import { Divider } from "../../Divider";
import { SidebarItem } from "./SidebarItem";
import { useAppRoutes } from "../../../hooks/useAppRoutes";


export interface LayoutSidebarProps {
}

export const LayoutSidebar = ({ }: LayoutSidebarProps) => {
	const routes = useAppRoutes();


	return (
		// <div className="w-16 flex flex-col p-2 bg-gray-200 transition-all hover:w-60">
		<div className={ classNames(["flex flex-col p-2 duration-700", "w-60"]) } >
			<div className="bg-black rounded-2xl flex-1">
				<SidebarHeader />
				<Divider />

				{
					routes.map((r, index) => {
						// if (r.hidden) return;
						switch (r.type) {
							case "hidden":
								return null;
							case "divider":
								return <Divider key={ `divider-${index}` } />
							case "collapse":
								return <SidebarItem key={ `${r.key}-${index}` } route={ r.path } childItems={ r.children } ItemIcon={ r.icon! } text={ r.title ?? "" }/>;
							case "link":
								// return <div key={ `div-${index}` }>NOT IMPLEMENTED</div>
								return <SidebarItem key={ `${r.key}-${index}` } route={ r.path } ItemIcon={ r.icon! } text={ r.title ?? "" }/>;
						}
					})
				}
			</div>
			{/*<div className="bg-black rounded-2xl flex-1 transition-all hover:w-60"></div>*/}
			{/*<div className={ classNames(["bg-black rounded-2xl flex-1 transition-all", open ? "w-56" : "w-14 hover:w-56" ]) }></div>*/}
		</div>
	)
}



