import { UsersTable } from "./usersTable";
import { useGetUsersQuery } from "../../_generated/graphql";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";



export const Users = () => {
	const navigate = useNavigate();

	const [ usersPageMeta, setUsersPageMeta ] = useState({ offset: 0, limit: 10 });

	const { data: { getUsers: usersPageData } = { }, loading: usersLoading, error: usersError } = useGetUsersQuery({ variables: { offset: usersPageMeta.offset, limit: usersPageMeta.limit } });

	const onUserEditClick = (userId: string) => {
		navigate(`${userId}`, { state: { _id: userId } });
	}

	const onUserDeleteClick = (userId: string, name: string) => {
		console.log(`Delete ${userId}  -  ${name}`)
	}

	if (usersLoading) {
		return (
			<div>Loading...</div>
		)
	}

	if (usersError) {
		return (
			<div>
				<span>Error</span>
				<p>
					{ usersError.message }
				</p>
			</div>
		)
	}


	return (
		<div>
			<UsersTable data={ usersPageData!.users } onEditClick={ onUserEditClick } onDeleteClick={ onUserDeleteClick } />
		</div>
	);
};



