import React, { MouseEvent, useMemo, useState } from "react";
import classNames from "classnames";
import ClickAwayListener from "react-click-away-listener";
import { MdOutlineArrowDropDown } from "react-icons/md";


type OptionsType = { value: string, text: string }

interface FormSelectProps {
	label?: string;
	placeholder?: string;
	className?: string;
	options: OptionsType[] | string[];
	error?: string;
	containerClassName?: string;
	selectClassName?: string;
	value?: string;
	onChange: (value: string) => void;
}


export const FormSelect = ({ label, placeholder, className, options, error, containerClassName, selectClassName, value, onChange }: FormSelectProps) => {

	const [ open, setOpen ] = useState(false);

	const optionsParsed = useMemo(() => {
		return options.map(o =>
			(typeof o === "string" ? { text: o, value: o } : o)
		)
	}, [ options ]);

	// const optionsParsed = options.map(o =>
	// 		(typeof o === "string" ? { text: o, value: o } : o)
	// 	);

	const [ buttonText, setButtonText ] = useState(optionsParsed.find(o => o.value === value)?.text ?? placeholder ?? 'Please select...');


	const onClickAway = () => {
		if (open) {
			setOpen(false);
			blurElement();
		}
	};


	const onItemClick = (event: MouseEvent<HTMLSpanElement>, optionValue: OptionsType) => {
		setButtonText(optionValue.text);
		setOpen(false);
		blurElement();
		onChange(optionValue.value);
	};


	const onButtonClick = () => {
		setOpen(!open);
		if (open)
			blurElement();
	};

	const blurElement = () => {
		const elem = document.activeElement as HTMLElement;
		if (elem) {
			elem.blur();
		}
	}


	return (
		<label className={ classNames([ "form-control w-full", containerClassName ]) }>
			<div className="label">
				<span className="label-text">{ label }</span>
			</div>
			<ClickAwayListener onClickAway={ onClickAway }>
				<div className={ classNames([ "dropdown dropdown-bottom dropdown-end", open && "dropdown-open", className ]) }>
					<div tabIndex={ 0 } role="button" className={ classNames([ "w-full min-h-12 bg-base-100 p-2 flex flex-row justify-end items-center rounded-t-lg", !open && "rounded-b-lg" ]) }
						 onClick={ () => onButtonClick() }>
						<span className="w-full">{ buttonText }</span>
						<MdOutlineArrowDropDown size={ '1.4rem' }/>
					</div>
					<ul tabIndex={ 0 } className={ classNames([ "dropdown-content menu z-[1] shadow bg-base-100 w-full rounded-b-lg", selectClassName ]) }>
						{ optionsParsed.map(o => (
							<li key={ `option-${ o.value }` }><span onClick={ (e) => onItemClick(e, o) }>{ o.text }</span></li>
						)) }
					</ul>
				</div>
			</ClickAwayListener>
			<div className="label">
				<span className={ `label-text-alt text-error ${ !error && 'hidden' }` }>{ error }</span>
			</div>
		</label>)
}

