import { useNavigate, useParams } from "react-router-dom";
import { useGetUserQuery, useSaveUserMutation } from "../../_generated/graphql";
import { Role, UserInput } from "shared-types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { setLoading } from "../../redux/slices/uiSettingsSlice";
import { useAppDispatch } from "../../redux/hooks";
import { FormTextInput } from "../../components/Form/FormTextInput";
import { FormSelect } from "../../components/Form/FormSelect";


export const EditUser = () => {

	const param = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();


	const { data: user, error: userError, loading: userLoading } = useGetUserQuery({ variables: { id: param.userId! } });
	const [ saveUser, { data: saveUserData, error: saveUserError, loading: saveUserLoading } ] = useSaveUserMutation();


	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		getValues,
		control,
		reset
	} = useForm<UserInput>({
		defaultValues: useMemo(() => {
			return user?.getUser;
		}, [ user ])
	})

	useEffect(() => {
		if (!userLoading && user) {
			reset(user.getUser)
		}
	}, [ user, userLoading ]);


	const onSubmit: SubmitHandler<UserInput> = (data) => {
		// console.log(data)
		dispatch(setLoading(true));
		saveUser({ variables: { user: data } })
			.then(value => {
				navigate('/users');
			})
			.finally(() => dispatch(setLoading(false)))
	}


	if (userLoading) {
		return (
			<div>Loading...</div>
		)
	}

	if (userError) {
		return (
			<div>
				<span>Error</span>
				<p>
					{ userError.message }
				</p>
			</div>
		)
	}
	if (saveUserError) {
		return (
			<div>
				<span>Error</span>
				<p>
					{ saveUserError.message }
				</p>
			</div>
		)
	}

	console.log(user?.getUser)

	return (
		<div className="flex flex-1 flex-col">
			<form onSubmit={ handleSubmit(onSubmit) }>
				<div className="flex flex-1 flex-col">
					<input type={ 'hidden' } { ...register('_id') } defaultValue={ user?.getUser._id }/>
					<FormTextInput label={ 'First Name' } { ...register('firstName') } />
					<FormTextInput label={ 'Last Name' } { ...register('lastName') } />
					<FormTextInput label={ 'Email' } { ...register('email') } />
					<Controller name={ "role" } control={ control }
								render={ ({ field: { ref, onChange, value }, fieldState, formState }) => {
									console.log(value)
									return ( <FormSelect options={ Object.values(Role) } onChange={ onChange } value={ value }/> )
								} }
					/>
				</div>
				<div className="flex flex-1 flex-row justify-between">
					<button className="btn btn-error">Cancel</button>
					<button className="btn btn-info">Save</button>
				</div>
			</form>
		</div>

	);
};

