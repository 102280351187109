import { Auth0Provider, useAuth0, User } from "@auth0/auth0-react";
import { AppState } from "@auth0/auth0-react/src/auth0-provider";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";


interface Auth0ProviderWithNavigateProps {

}

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

export default function Auth0ProviderWithNavigate({ children }: PropsWithChildren<Auth0ProviderWithNavigateProps>) {
	const navigate = useNavigate();


	const onRedirectCallback = async (appState?: AppState, user?: User) => {
		navigate(appState?.returnTo || window.location.pathname);
		// navigate('dashboard');
	};

	if (!(domain && clientId && redirectUri)) {
		return null;
	}

	return (
		<Auth0Provider
			domain={ domain }
			clientId={ clientId }
			authorizationParams={ {
				redirect_uri: redirectUri,
				audience,
				scope: 'openid profile email',
			} }
			useRefreshTokens={ true }
			cacheLocation="localstorage"
			useCookiesForTransactions
			onRedirectCallback={ onRedirectCallback }
		>
			{ children }
		</Auth0Provider>
	);
};



