import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { UserInput } from "shared-types";


// interface User {
// 	_id: string;
// 	firstName: string;
// 	lastName: string;
// 	email: string;
// 	avatarUrl?: string
// }

interface UserInfoState {
	user?: UserInput;
	token?: string;
}

const initialState: UserInfoState = { }

export const userInfoSlice = createSlice({
	name: 'userInfo',
	initialState,
	reducers: {
		setUserInfo: (state, action: PayloadAction<UserInput>) => {
			state.user = {
				_id: action.payload._id,
				email: action.payload.email,
				firstName: action.payload.firstName,
				lastName: action.payload.lastName,
				picture: action.payload.picture,
				role: action.payload.role,
			}
		},
		setFirstName: (state, action: PayloadAction<string>) => {
			state.user!.firstName = action.payload;
		},
		setLastName: (state, action: PayloadAction<string>) => {
			state.user!.lastName = action.payload;
		},
		setEmail: (state, action: PayloadAction<string>) => {
			state.user!.email = action.payload;
		},
		setAvatarUrl: (state, action: PayloadAction<string>) => {
			state.user!.picture = action.payload;
		},
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		clearUser: (state) => {
			state.user = undefined;
			state.token = undefined;
		},
	}
});


export const {
	setFirstName, setLastName, setEmail,
	setUserInfo, setAvatarUrl, setToken, clearUser
} = userInfoSlice.actions;

export const selectUserInfoState = (state: RootState) => state.userInfo;

export default userInfoSlice.reducer;

