import { LayoutSidebar } from "./sidebar/LayoutSidebar";
import { LayoutHeader } from "./header/LayoutHeader";
import { Outlet } from "react-router-dom";
import { useState } from "react";


export const Layout = () => {

	// const [ menuOpen, setMenuOpen ] = useState<boolean>(true)

	const onMenuClick = () => {
		// setMenuOpen(!menuOpen);
	}

	return (
		<div className="flex flex-row flex-1 w-screen h-screen">
			<LayoutSidebar />
			<div className="flex flex-1 flex-col">
				<LayoutHeader onMenuClick={ onMenuClick }/>
				<div className="flex flex-1 p-8 overflow-scroll">
					<Outlet/>
				</div>
			</div>
		</div>
	)


}



